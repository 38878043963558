import { Button, FormControl, Input, marginUtil, marginVertical, Modal, ModalRole } from "@gadgetinc/widgets";
import React, { useCallback, useState } from "react";
import type { TeamProps } from "state-trees/src/Team";
import useFetch from "use-http";

const useEditTeamName = (team: TeamProps) => {
  const [newTeamName, setNewTeamName] = useState(team.name);

  const { loading, post: updateTeam } = useFetch<{ team: TeamProps }>(`/auth/api/team/${team.id}`);

  const save = async () => {
    const result = await updateTeam({ name: newTeamName });

    if ("team" in result) {
      return { team: result.team as TeamProps };
    }
  };

  const onChange = useCallback((e: { currentTarget: { value: string } }) => setNewTeamName(e.currentTarget.value), [setNewTeamName]);

  return {
    value: newTeamName,
    submitting: loading,
    onChange,
    save,
  };
};

export const RenameTeamModal = (props: { team: TeamProps; isOpen: boolean; onClose: (newName?: string) => void }) => {
  const { team, isOpen, onClose } = props;
  const editTeamName = useEditTeamName(team);

  const onSave = useCallback(async () => {
    await editTeamName.save();
    onClose(editTeamName.value);
  }, [editTeamName, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        editTeamName.onChange({
          currentTarget: {
            value: team.name,
          },
        });
        onClose();
      }}
      autoFocus
      role={ModalRole.dialog}
      overrides={{
        Dialog: {
          style: {
            width: "500px",
            display: "flex",
            flexDirection: "column",
          },
        },
        ModalBody: {
          style: {
            ...marginVertical(0),
          },
        },
      }}
      title="Create team"
      footer={
        <Button disabled={editTeamName.submitting} onClick={onSave}>
          Confirm
        </Button>
      }
    >
      <FormControl
        overrides={{
          ControlContainer: {
            style: { ...marginUtil(0) },
          },
        }}
      >
        <Input
          placeholder="Team name"
          value={editTeamName.value}
          onChange={(event) => editTeamName.onChange(event)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              void onSave();
            }
          }}
        />
      </FormControl>
    </Modal>
  );
};
