import { Button, FormControl, Input, Modal, ModalRole, marginUtil, marginVertical } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import React, { useCallback, useState } from "react";
import type { InitialBillingPlanProps } from "state-trees/src/Team";
import useFetch, { CachePolicies } from "use-http";
import { useLocation } from "wouter";

export const CreateNewTeamModal = (props: { initialPlan: InitialBillingPlanProps; isOpen: boolean; onClose: () => void }) => {
  const { isOpen, onClose, initialPlan } = props;
  const [_css] = useStyletron();

  const [_location, setLocation] = useLocation();

  const { data: newTeamData, loading: loadingNewTeamData } = useFetch<
    { userEmail: string; allowed: boolean } & (
      | { paymentRequired: true; createTeamTransactionAmount: string }
      | { paymentRequired: false; createTeamTransactionAmount: null }
    )
  >(`/auth/api/team/new?billingPlanId=${initialPlan.id}`, { cachePolicy: CachePolicies.NETWORK_ONLY }, [initialPlan]);

  const { loading: creatingTeam, post: createTeam } = useFetch("/auth/api/team", { cachePolicy: CachePolicies.NETWORK_ONLY });

  const [teamName, setTeamName] = useState("");
  const [error, setError] = useState<string | undefined>();

  const close = useCallback(() => {
    setTeamName("");
    onClose();
  }, [onClose, setTeamName]);

  const createNewTeam = useCallback(
    async (teamName: string) => {
      setError(undefined);
      if (!newTeamData) {
        setError("unable to create team");
        return;
      }

      if (newTeamData.allowed && !newTeamData.paymentRequired) {
        const createTeamResult = (await createTeam({
          teamName,
          billingAccountEmail: newTeamData.userEmail,
          billingPlanId: initialPlan.id,
        })) as { success: false; reason: string } | { success: true; billingAccountId: string; teamId: string; setupIntentSecret: string };

        if (createTeamResult.success) {
          window.location.href = `/auth/team/select?teamId=${createTeamResult.teamId}`;
        } else {
          setError(createTeamResult.reason);
        }
      } else {
        setLocation(`/auth/team/new?teamName=${encodeURIComponent(teamName)}`);
        close();
      }
    },
    [newTeamData, setLocation, close, createTeam, initialPlan]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      autoFocus
      role={ModalRole.dialog}
      overrides={{
        Dialog: {
          style: {
            width: "500px",
            display: "flex",
            flexDirection: "column",
          },
        },
        ModalBody: {
          style: {
            ...marginVertical(0),
          },
        },
      }}
      title="Create team"
      footer={
        <Button
          disabled={loadingNewTeamData || !teamName || creatingTeam}
          onClick={async () => {
            await createNewTeam(teamName);
          }}
        >
          Confirm
        </Button>
      }
    >
      <FormControl
        error={error}
        overrides={{
          ControlContainer: {
            style: { ...marginUtil(0) },
          },
        }}
      >
        <Input
          placeholder="Team name"
          value={teamName}
          onChange={(event) => setTeamName(event.currentTarget.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              void createNewTeam(teamName);
            }
          }}
        />
      </FormControl>
    </Modal>
  );
};
