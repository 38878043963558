import { Body, Column, DropdownMenu, Row } from "@gadgetinc/widgets";
import { CheckmarkIcon } from "@gadgetinc/widgets/src/icons/CheckmarkIcon";
import { EditIcon } from "@gadgetinc/widgets/src/icons/EditIcon";
import { PlusIcon } from "@gadgetinc/widgets/src/icons/PlusIcon";
import { useStyletron } from "baseui";
import { compact } from "lodash";
import React, { useMemo, useState } from "react";
import type { InitialBillingPlanProps, TeamProps } from "state-trees/src/Team";
import { useLocation } from "wouter";
import { getPlanColors } from "./Billing/utils";
import { CreateNewTeamModal } from "./CreateNewTeamModal";
import { RenameTeamModal } from "./RenameTeamModal";

const TeamItem = (props: { team: TeamProps; selected?: boolean; bold?: boolean }) => {
  const { team, selected, bold } = props;
  const [_, $theme] = useStyletron();
  const { color: planColor } = getPlanColors(team.billingPlanName, $theme);

  return (
    <Row $gap={$theme.sizing.scale300} $align="space-between">
      <Column>
        <Body style={{ fontWeight: bold ? 700 : undefined }}>{team.name}</Body>
        <Body style={{ color: planColor }}>{team.billingPlanName}</Body>
      </Column>
      {selected && <CheckmarkIcon />}
    </Row>
  );
};

export const TeamDropdown = (props: {
  team: TeamProps;
  otherTeams: TeamProps[];
  initialPlan: InitialBillingPlanProps;
  readonly?: boolean;
}) => {
  const { otherTeams, initialPlan, readonly } = props;
  const [_, $theme] = useStyletron();
  const [location] = useLocation();
  const [newTeamModalOpen, setNewTeamModalOpen] = useState(false);
  const [renameTeamModalOpen, setRenameTeamModalOpen] = useState(false);
  const [currentTeam, setCurrentTeam] = useState(props.team);

  const menuItems = useMemo(() => {
    return compact([
      ...[currentTeam, ...otherTeams].map((t) => ({
        label: <TeamItem team={t} selected={t.id === currentTeam.id} />,
        onSelect: () => (window.location.href = `/auth/team/select?teamId=${t.id}&redirect=${location}`),
      })),
      ...(!readonly
        ? [
            {
              divider: true,
            },
            {
              label: (
                <Row $gap={$theme.sizing.scale300}>
                  <EditIcon />
                  <Body>Rename team</Body>
                </Row>
              ),
              onSelect: () => setRenameTeamModalOpen(true),
            },
            {
              label: (
                <Row $gap={$theme.sizing.scale300}>
                  <PlusIcon />
                  <Body>Create team</Body>
                </Row>
              ),
              onSelect: () => setNewTeamModalOpen(true),
            },
          ]
        : []),
    ]);
  }, [location, otherTeams, currentTeam]);

  return (
    <>
      <DropdownMenu
        items={menuItems}
        fullWidth
        overrides={{
          menu: {
            List: {
              style: {
                width: "236px",
                overflow: "auto",
                boxShadow: "none",
                boxSizing: "content-box",
                paddingTop: $theme.sizing.scale200,
                paddingBottom: $theme.sizing.scale200,
              },
            },
          },
        }}
      >
        <TeamItem team={currentTeam} bold />
      </DropdownMenu>
      <CreateNewTeamModal initialPlan={initialPlan} isOpen={newTeamModalOpen} onClose={() => setNewTeamModalOpen(false)} />
      <RenameTeamModal
        team={currentTeam}
        isOpen={renameTeamModalOpen}
        onClose={(newName) => {
          setRenameTeamModalOpen(false);
          if (newName) {
            setCurrentTeam({
              ...currentTeam,
              name: newName,
            });
          }
        }}
      />
    </>
  );
};
